import React from "react";
import SectionHeader from "./SectionHeader";
const SectionWho = () => {
  return (
    <section className="who content">
      <span className="anchor about"></span>
      <div className="flex-height"></div>
      <SectionHeader text="Who we are" />
      <article className="who__content">
        <span className="who__content--image"></span>
        <span className="who__content--description">
          We are team of 10 IT professionals, mostly graduated from Cracow AGH
          University of Science and Technology. Connected by passion to making
          top technologies work for your business.
        </span>
      </article>
    </section>
  );
};
export default SectionWho;
