import React from "react";
import Navigation from "./Navigation";
const HeaderSection = () => {
  return (
    <section className="head">
      <div className="head__overlay"></div>
      <Navigation />
    </section>
  );
};

export default HeaderSection;
