import React from "react";

const Decorator = (props) => {
  return (
    <>
      <article
        className="decorator__wrapper"
        style={{
          transform: `rotate(${props.rotate}deg)`,
        }}
      >
        <section className="decorator__first">
          <span className="decorator--logo"></span>
          <span className="decorator--text">
            0101010001 010 10 010 101 0010000101001 00
          </span>
        </section>
        <section className="decorator__second">
          <span className="decorator--logo"></span>
          <span className="decorator--text">Softwarehouse of the future</span>
        </section>
      </article>
    </>
  );
};

export default Decorator;
