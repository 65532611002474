import React, { useState, useEffect } from "react";
import { ReactComponent as ReactLogo } from "../../assets/images/arrow.svg";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
const Navigation = () => {
  let history = useHistory();
  let location = useLocation();
  const [navVisibility, setNavVisibility] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollHandler = (event) => {
    setScrollTop(event.target.scrollTop);
  };

  useEffect(() => {
    document.body.addEventListener("scroll", scrollHandler);
    return () => {
      document.body.removeEventListener("scroll", scrollHandler);
    };
  });
  useEffect(() => {
    let address = location.pathname.split("/");
    if (address[1] === "careers") {
      if (address.length <= 2) {
        quickJump(address[1]);
      } else {
        if (address[2] == "offer") {
          location.state = { offer: true };
        }
      }
    } else {
      quickJump(address[1]);
    }
  }, [location]);

  const quickJump = (name) => {
    if (!name) {
      return false;
    }
    let link = document.getElementsByClassName(name);
    if (link) {
      link[0].scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      this.history.push("/");
    }
  };

  const handleClick = (event, name) => {
    event.preventDefault();

    history.push("/" + name);
  };
  return (
    <>
      <article
        className={`navigation sticky ${scrollTop > 100 ? "visible" : ""}`}
      >
        <div className="navigation__logo"></div>
        <nav className="navigation__nav">
          <ul>
            <li className="navigation__nav__anchor">
              <a
                href="#careers"
                onClick={(event) => {
                  handleClick(event, "careers");
                }}
              >
                Careers
              </a>
            </li>
            <li className="navigation__nav__anchor">
              <a
                href="#about"
                onClick={(event) => {
                  handleClick(event, "about");
                }}
              >
                About
              </a>
            </li>
            <li className="navigation__nav__anchor">
              <a
                href="#contact"
                onClick={(event) => {
                  handleClick(event, "contact");
                }}
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </article>
      <article className="navigation">
        <div className="navigation__logo"></div>
        <Router>
          <nav className="navigation__nav">
            <ul>
              <li className="navigation__nav__anchor">
                <a
                  href="#careers"
                  onClick={(event) => {
                    handleClick(event, "careers");
                  }}
                >
                  Careers
                </a>
              </li>
              <li className="navigation__nav__anchor">
                <a
                  href="#about"
                  onClick={(event) => {
                    handleClick(event, "about");
                  }}
                >
                  About
                </a>
              </li>
              <li className="navigation__nav__anchor">
                <a
                  href="#contact"
                  onClick={(event) => {
                    handleClick(event, "contact");
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </Router>
        <nav className="navigation__nav--mobile">
          <button onClick={() => setNavVisibility(!navVisibility)}>
            <i className="fa fa-bars"></i>
          </button>
          <div className={`mobile-navigation ${navVisibility ? "expand" : ""}`}>
            <button
              className="close-nav"
              onClick={() => setNavVisibility(false)}
            >
              <i className="fa fa-times"></i>
            </button>
            <ul>
              <li
                onClick={() => setNavVisibility(false)}
                className="navigation__nav__anchor"
              >
                <a
                  href="#careers"
                  onClick={(event) => {
                    handleClick(event, "careers");
                  }}
                >
                  Careers
                </a>
              </li>
              <li
                onClick={() => setNavVisibility(false)}
                className="navigation__nav__anchor"
              >
                <a
                  href="#about"
                  onClick={(event) => {
                    handleClick(event, "about");
                  }}
                >
                  About
                </a>
              </li>
              <li
                onClick={() => setNavVisibility(false)}
                className="navigation__nav__anchor"
              >
                <a
                  href="#contact"
                  onClick={(event) => {
                    handleClick(event, "contact");
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </article>
      <article className="head__hero">
        <div className="separator"></div>
        <h1 className="head__hero--main">Software</h1>
        <h1 className="head__hero--main"> development </h1>
        <h1 className="head__hero--main"> for your business</h1>
        <div className="head__hero--secondary">
          Since 2016 we are empowering our customers with Hi-Tech software
          solutions, allowing them to lower the cost of operation and speed up
          processes.
        </div>
        <div className="head__hero--arrow">
          <ReactLogo />
        </div>
      </article>
    </>
  );
};

export default Navigation;
