import React from "react";

const SectionSkills = () => {
  return (
    <ul className="how-type__content--list skills">
      <li className="how-type__content--list-item">Prototyping</li>      
      <li className="how-type__content--list-item">Design Creation </li>
      <li className="how-type__content--list-item">Problem Solving </li>
      <li className="how-type__content--list-item">Flexibility</li>
      <li className="how-type__content--list-item">Adaptability </li>
    </ul>
  );
};

export default SectionSkills;
