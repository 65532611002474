import React from "react";
import SectionHeader from "./SectionHeader";
import SectionWhatContent from "./what/SectionWhatContent";
let content = [
  {
    icon: "fas fa-globe",
    header: "Complex Web Applications",
    description:
      " We develop complete web solutions with a professional look & feel using latest technologies and trends.",
    technologies: [
      "Responsive Web Design (RWD)",
      "User Interface (UI) Design",
      "User Experience (UX) Design",
      "Angular/React framework",
      "HTML5/CSS3",
    ],
  },
  {
    icon: "fas fa-cloud",
    header: "Cloud-driven Solutions",
    description: "We use cloud services to empower our projects.",
    technologies: [
      "Microsoft Azure",
      "C#/.Net",
      "User Experience (UX) Design",
      "SQl/NoSQL/Graph db",
      "Machine Learning",
      "Resource virtualization & clustering",
    ],
  },
  {
    icon: "fas fa-laptop-code",
    header: "Mobile & Desktop",
    description:
      "We create mobile and desktop consumer apps to benefit from complex backend services.",
    technologies: ["Progresive Web Apps", "WPF/WinForms"],
  },
  {
    icon: "fas fa-lightbulb",
    header: "R&D projects",
    description: "Anything to test the idea.",
    technologies: ["Technology of your choice"],
  },
];
let contentItems = [];
for (let i = 0; i < content.length; i++) {
  contentItems.push(
    <SectionWhatContent key={i} content={content[i]}></SectionWhatContent>
  );
}
const SectionWhat = () => {
  return (
    <section className="what content">
      <SectionHeader text="What we do" />
      {contentItems}
    </section>
  );
};

export default SectionWhat;
