import React from "react";

const CareerResume = (props) => {
  return (
    <>
      <div className="career__item--content">
        <h1 className="career__item--name">Resume Form</h1>
        <button
          className="career__item--close"
          onClick={(e) => props.closeResumeWindow(e)}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    </>
  );
};

export default CareerResume;
