import React from "react";
import Section from "./Section";
const SectionHowType = () => {
  return (
    <>
      {/* <section className="how-type">
        <article className="how-type__content">
          <h1 className="how-type__content--head">Technology</h1>
          <span className="how-type__content--description">
            Ready to build the product you need
          </span>
          <Section.Technology />
        </article>
      </section>
      <section className="how-type">
        <article className="how-type__content">
          <h1 className="how-type__content--head">Skills</h1>
          <span className="how-type__content--description">
            Ready to build the product you need
          </span>
          <Section.Skills />
        </article>
      </section> */}
      <section className="how-type">
        <article className="how-type__content">
          <h1 className="how-type__content--head">Experience</h1>
          <span className="how-type__content--description">
            Ready to build the product you need
          </span>
          <Section.Experience />
        </article>
      </section>
    </>
  );
};

export default SectionHowType;
