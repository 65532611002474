import React from "react";
import SectionHeader from "./SectionHeader";
import SectionHowType from "./sectionTypes/SectionHowType";
const SectionHow = () => {
  return (
    <section className="how content">
      <SectionHeader text="How we do it" />
      <SectionHowType />
    </section>
  );
};

export default SectionHow;
