import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import CareerContent from "./CareerContent";
import { Switch, Link, Route, useHistory, useLocation } from "react-router-dom";
const CareerMain = (props) => {
  let history = useHistory();
  let location = useLocation();
  const [visibleDetails, setVisibleDetails] = useState(false);
  const func = (event) => {
    event.stopPropagation();
    event.preventDefault();
    history.push("/careers");
    setVisibleDetails(false);
  };

  useEffect(() => {
    if (location.state?.offer) {
      setVisibleDetails(true);
    }
  }, [location]);

  return (
    <>
      <Link
        to={{
          pathname: "/careers/offer/1",
          state: { offer: true },
        }}
        onClick={() => {
          setVisibleDetails(true);
        }}
      >
        <li className="career__item">
          <h2 className="career__title">{props.title}</h2>
          <span className="career__arrow"></span>
        </li>
      </Link>
      <CSSTransition
        in={visibleDetails}
        timeout={500}
        classNames="details"
        unmountOnExit
      >
        <Switch>
          <Route
            exact
            path="/careers/offer/:id"
            children={<CareerContent closeDetailsWindow={func} />}
          ></Route>
        </Switch>
      </CSSTransition>
    </>
  );
};

export default CareerMain;
