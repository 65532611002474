import React from "react";

const SectionTechnology = () => {
  return (
    <ul className="how-type__content--list technology">
      <li className="how-type__content--list-item">
        <div className="technology__icon js"></div>
      </li>
      <li className="how-type__content--list-item">
        <div className="technology__icon vue"></div>
      </li>
      <li className="how-type__content--list-item">
        <div className="technology__icon sf"></div>
      </li>
      <li className="how-type__content--list-item">
        <div className="technology__icon angular"></div>
      </li>
      <li className="how-type__content--list-item">
        <div className="technology__icon vs"></div>
      </li>
      <li className="how-type__content--list-item">
        <div className="technology__icon react"></div>
      </li>
      <li className="how-type__content--list-item mysql">
        <div className="technology__icon mysql"></div>
      </li>
    </ul>
  );
};

export default SectionTechnology;
