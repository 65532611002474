import React from "react";

const CareerContent = (props) => {
  return (
    <>
      <div className="career__item--overlay"></div>
      <div className="career__item--content">
        <h1 className="career__item--name">Azure Cloud Architect</h1>
        <div className="career__item--close__wrapper">
          <button
            className="career__item--close"
            onClick={(e) => props.closeDetailsWindow(e)}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
        <span className="career__item--introduction">
          <h3>Job Description</h3>
          <p>
            DevCity is looking for a senior C#/Azure developer or architect
            willing to design cloud based solutions and lead a small team of
            developers.
          </p>
        </span>
        <span className="career__item--responsibilities">
          <h3>Responsibilities</h3>
          <ul className="career__item__list">
            <li className="career__item__list-item">
              design cloud based solution
            </li>
            <li className="career__item__list-item">team leading</li>
            <li className="career__item__list-item">
              implementation - part time
            </li>
          </ul>
        </span>
        <span className="career__item--requirements">
          <h3>Required Experience</h3>
        </span>
        <ul className="career__item__list">
          <li className="career__item__list-item">
            5+ years in C#/.Net Framework
          </li>
          <li className="career__item__list-item">3+ years in Azure Cloud</li>
        </ul>
        <span className="career__item--requirements">
          <h3>Required Skills</h3>
        </span>
        <ul className="career__item__list">
          <li className="career__item__list-item">Design Patterns</li>
          <li className="career__item__list-item">C#/.Net Framework</li>
          <li className="career__item__list-item">PowerShell</li>
          <li className="career__item__list-item">
            Azure Cloud, especially App Service, Virtual Machines, Virtual
            Network, App Fabric, App Gateway, Application Insights, B2C
          </li>{" "}
          <li className="career__item__list-item">
            Software testing methodology
          </li>
        </ul>
        <a
          href="mailto:hello@devcity.com?subject=Job Offer: Azure Cloud Architect"
          className="career__resume"
        >
          Send Resume
        </a>{" "}
      </div>
    </>
  );
};

export default CareerContent;
