import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import Main from "./components/Main";
import { BrowserRouter as Router } from "react-router-dom";
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Main />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
