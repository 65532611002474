import React from "react";

const SectionWhatContent = (props) => {
  return (
    <>
      <article className="what__content">
        <section className="what__content--column">
          <i className={`what__content--icon ${props.content.icon}`}></i>
          <h3 className="what__content--head">{props.content.header}</h3>
          <span className="what__content--description">
            {props.content.description}
          </span>
        </section>
        <ul className="what__content--list">
          {props.content.technologies.map((technology, index) => {
            return (
              <li key={index} className="what__content--list-item">
                {technology}
              </li>
            );
          })}
        </ul>
      </article>
    </>
  );
};

export default SectionWhatContent;
