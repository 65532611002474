import React from "react";
import HeaderSection from "./head/HeaderSection";
import SectionWho from "./content/SectionWho";
import SectionWhat from "./content/SectionWhat";
import SectionHow from "./content/SectionHow";
import SectionCareer from "./content/SectionCareer";
import SectionContact from "./content/SectionContact";
import Decorator from "./content/Decorator";
import ScrollToTop from "./utils/ScrollToTop";
const Main = () => {
  return (
    <>
      <HeaderSection />
      <SectionWho />
      <SectionWhat />
      <SectionHow />
      <SectionCareer />
      <SectionContact />
      <Decorator rotate={90} />
      <Decorator rotate={-90} />
      <ScrollToTop />
    </>
  );
};

export default Main;
