import React, { Fragment } from "react";
import SectionTechnology from "./SectionTechnology";
import SectionSkills from "./SectionSkills";
import SectionExperience from "./SectionExperience";
const Section = (props) => {
  const { children } = props;
  return <>{children}</>;
};

const Technology = ({ children }) => {
  return (
    <Fragment>
      <SectionTechnology />
    </Fragment>
  );
};

const Skills = ({ children }) => {
  return (
    <Fragment>
      <SectionSkills />
    </Fragment>
  );
};

const Experience = ({ children }) => {
  return (
    <Fragment>
      <SectionExperience />
    </Fragment>
  );
};

Section.Technology = Technology;
Section.Skills = Skills;
Section.Experience = Experience;

export default { Section, Technology, Skills, Experience };
