import React from "react";
import SectionHeader from "./SectionHeader";
const Contact = () => {
  return (
    <section className="contact__wrapper">
      <span className="anchor contact"></span>
      <div className="contact">
        <SectionHeader text="Contact_" />
        <div className="contact__info">
          <span className="separator"></span>

          <div className="contact__info--columns">
            <span className="contact__info--wrapper">
              <span className="info__head">Say Hello</span>
              <h2 className="info__head--big">hello&#64;devcity&#46;eu</h2>
              <h2 className="info__head--big">+48 570576519</h2>
            </span>
            <span className="contact__info--wrapper">
              <span className="info__head">Postal Address</span>
              <h2 className="info__head--big">ul. Rakowicka 20G/8</h2>
              <h2 className="info__head--big">31-510 Kraków</h2>
            </span>
            <span className="contact__info--wrapper">
              <span className="info__head">Invoice Data</span>
              <h2 className="info__head--big">DevCity sp. z o.o.</h2>
              <h2 className="info__head--big">NIP 6751655147</h2>
              <h2 className="info__head--big">invoices&#64;devcity&#46;eu</h2>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
