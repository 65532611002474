import React from "react";

const SectionExperience = () => {
  return (
    <ul className="how-type__content--list experience">
      <li className="how-type__content--list-item">
        <span className="experience-big">4+</span>
        <span className="experience-small">Years on the market</span>
      </li>
      <li className="how-type__content--list-item">
        <span className="experience-big">10+</span>
        <span className="experience-small">avg engineer experience (years)</span>
      </li>      
      <li className="how-type__content--list-item">
        <span className="experience-big">99K+</span>
        <span className="experience-small">written Lines of code</span>
      </li>
    </ul>
  );
};

export default SectionExperience;
