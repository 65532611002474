import React from "react";
import { useEffect, useState } from "react";

const ScrollToTop = () => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = (event) => {
    setOffsetY(document.body.scrollTop);
  };

  useEffect(() => {
    document.body.addEventListener("scroll", handleScroll);
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  });

  const scrollBack = () => {
    console.log("click");
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`scrollTop ${offsetY > 100 ? "reveal" : ""}`}
      onClick={() => {
        scrollBack();
      }}
    >
      <span className="scrollTop__arrow"></span>
    </div>
  );
};

export default ScrollToTop;
