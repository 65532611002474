import React, { useState } from "react";
import CareerMain from "./career/CareerMain";
import SectionHeader from "./SectionHeader";
import { CSSTransition } from "react-transition-group";
import CareerResume from "./career/CareerResume";
const SectionCareer = () => {
  let items = [];
  const [visibleResume, setVisibleResume] = useState(false);
  const func = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setVisibleResume(false);
  };
  items.push(<CareerMain title={"Azure Cloud Architect"} key={0} />);

  return (
    <section className="career content">
      <span className="anchor careers"></span>

      <SectionHeader text="Career" />
      <p>We are currently looking for:</p>

      <ul className="career__list">{items}</ul>
      <p className="career__shortdesc">
        These aren’t the job offers you’re looking for?
      </p>
      <h2 className="career__subheader">
        Get in touch with us – we’re more than happy to meet you!
      </h2>
      <a href="mailto:hello@devcity.com" className="career__resume">
        Send Resume
      </a>
      <CSSTransition
        in={visibleResume}
        timeout={200}
        classNames="details"
        unmountOnExit
      >
        <CareerResume closeResumeWindow={func} />
      </CSSTransition>
    </section>
  );
};

export default SectionCareer;
